/**
 * Add to any input field "float-field" class to make it work.
 * Requires: jQuery
 * TODO: if input has own label, ignore to create another label
 */
var floatLabel = function() {
    init();
};

/**
 * Initializing
 */
var init = function() {
    $('.float-field').each(function(index) {
        var inputField = $(this),
            labelText,
            idField, label;

        if(inputField.is('select')) {
            labelText = inputField.find('>:first-child').text();
        }
        else
            labelText = inputField.attr('placeholder');

        if(typeof inputField.attr('id') !== typeof undefined && inputField.attr('id') !== false)
            idField = inputField.attr('id');
        else {
            idField = inputField.attr('name');
            inputField.attr('id', idField);
        }

        if(!inputField.val())
            inputField.wrap("<div class='float-group'></div>");
        else
            inputField.wrap("<div class='float-group not-empty'></div>");

        label = "<label for='" + idField + "' class='float-label'>" + labelText + "</label>";
        inputField.after(label);
    });

    addFloatClass('.float-field', 'input');
    addFloatClass('.float-field.datetimepicker', 'dp.change');
};

/**
 * Adds not-empty class to the parent
 * .group class of element when event is fired
 *
 * @param element
 * @param event
 */
var addFloatClass = function (element, event) {
    $(element).on(event, function() {
        var $field = $(this).closest('.float-group');
        if (this.value) {
            $field.addClass('not-empty');
        } else {
            $field.removeClass('not-empty');
        }
    });
};